<template>
  <div class="page-content">
    <h1 class="page-content__title">Темы</h1>
    <p class="page-content__description">
      You can pass a modified theme to the ThemeProvider to change the look and feel of the components.
    </p>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>

</style>
